import React, { FC } from 'react';
import { MarqueeItemProps } from './Marquee.types';

// components
import { Box, Stack } from 'src/components-v2/Layout';
import { Typography } from 'src/components-v2/DataDisplay';
import { Image } from 'src/components-v2/Media';
import { MarqueeBackgroundImage } from 'src/sections/ThreadsEvent/23/Marquee';

const MarqueeItem: FC<MarqueeItemProps> = ({
  title,
  image,
  bgImageStyles,
  scrollTo,
  handleClick,
  isMirrored = false,
}) => {
  return (
    <Box
      component='li'
      sx={{
        listStyleType: 'none',
        borderRight: { xs: 0.7, md: 1 },
        py: { xs: '1.2rem', md: '2rem' },
        px: { xs: '2.5rem', md: '4rem' },
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <MarqueeBackgroundImage styles={bgImageStyles} />
      <Stack
        component='button'
        role='button'
        onClick={() => {
          handleClick(scrollTo);
        }}
        flexDirection={isMirrored ? 'row-reverse' : 'row'}
        alignItems='center'
        gap='2rem'
      >
        {image && (
          <Image
            sx={{
              border: '1px solid black !important',
              borderRadius: { xs: '42px', md: '71px' },
              width: { xs: '104px', md: '120px', lg: '175px' },
              height: { xs: '60px', md: '70px', lg: '99px' },
            }}
            src={image.src + '?fm=webp&w=350&q=90'}
            alt={image.alt}
            width='175px'
            height='99px'
            sources={[
              {
                media: '(max-width: 639px)',
                srcSet: `${image.src + '?fm=webp&w=208&q=100'}`,
              },
              {
                media: '(min-width: 640px)',
                srcSet: `${image.src + '?fm=webp&w=350&q=90'}`,
              },
            ]}
          />
        )}
        <Typography
          variant='h2'
          component='span'
          theme='threads-event-23'
          sx={{
            fontSize: '3.6rem',
            lineHeight: '5.4rem',
            letterSpacing: '-0.254rem',
            px: { xs: '1rem', md: '1.6rem' },
            mb: '0',
          }}
        >
          {title}
        </Typography>
      </Stack>
    </Box>
  );
};

export default MarqueeItem;
