import React from 'react';
import { keyframes } from '@mui/system';
import { Box } from 'src/components-v2/Layout';
import { Typography } from 'src/components-v2/DataDisplay';

export const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const TimeBox = ({ children, color }) => {
  return (
    <Box
      sx={{
        bgcolor: `${color}`,
        border: 'solid 1px #111111',
        borderRadius: '12.56px',
        color: 'black1000',
        fontFamily: 'sans',
        fontSize: '1.6rem',
        fontWeight: 600,
        height: '48px',
        m: 0,
        ml: '-1px',
        p: '1.05rem 0.4rem 1.2rem',
        position: 'relative',
        animation: `${fadeIn} 0.3s 1 ease-in-out`,
      }}
    >
      <Typography
        theme='threads-event-23'
        sx={{ fontWeight: 600, fontSize: '1.6rem' }}
      >
        {children}
      </Typography>
    </Box>
  );
};

export default TimeBox;
