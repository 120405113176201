import * as React from 'react';
import styled from '@mui/system/styled';
import { keyframes } from '@mui/system';
import Video from 'plaid-threads/Icons/Video';

export const color = keyframes`
  0% {
    color: #111111;
  }
  50% {
    color: #0A85EA;
  }
  100% {
    color: #111111;
  }
`;

const StyledA = styled('a')(() => {
  return {
    height: '48px',
    padding: '12px 20px',
    backgroundColor: '#ECF951',
    border: '1px solid black',
    borderRadius: '12.56px',
    fontSize: '1.6rem',
    fontWeight: '600',
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    lineHeight: '2.4rem',
    letterSpacing: '0.024rem',
    animation: `${color} 2s ease infinite`,
  };
});

const LiveButton = () => {
  return (
    <StyledA href='https://threads.plaid.com/' tabIndex={0}>
      Now Live <Video />
    </StyledA>
  );
};

export default LiveButton;
