import * as React from 'react';
import Countdown from 'react-countdown';
import { animated, useSpring, easings } from '@react-spring/web';

import { Stack, Box as VBox } from 'src/components-v2/Layout';
import AnimatedDigits from './AnimatedDigits';
import TimeBox from './TimeBox';
import LiveButton from './LiveButton';

const Box = animated(VBox);

const START_DATE = new Date(Date.UTC(2023, 5, 22, 16, 0, 0));

const FlipClock = () => {
  const shouldRender = React.useRef(false);
  const [shouldCollapseMinutes, setShouldDislpayMinutes] = React.useState(true);
  const [shouldCollapseHours, setShouldDisplayHours] = React.useState(true);
  const [shouldCollapseDays, setShouldDisplayDays] = React.useState(true);

  const collapseMinutes = useSpring({
    from: {
      width: 69,
      opacity: 1,
    },
    to: {
      width: shouldCollapseMinutes ? 69 : 0,
      opacity: shouldCollapseMinutes ? 1 : 0,
    },
    config: {
      duration: 150,
      easing: easings.easeInQuad,
    },
  });

  const collapseHours = useSpring({
    from: { width: 58, opacity: 1 },
    to: {
      width: shouldCollapseHours ? 58 : 0,
      opacity: shouldCollapseHours ? 1 : 0,
    },
    config: {
      duration: 150,
      easing: easings.easeInQuad,
    },
  });

  const collapseDays = useSpring({
    from: { width: 69, opacity: 1 },
    to: {
      width: shouldCollapseDays ? 69 : 0,
      opacity: shouldCollapseDays ? 1 : 0,
    },
    config: {
      duration: 150,
      easing: easings.easeInQuad,
    },
  });

  return (
    <Countdown
      date={START_DATE}
      precision={1}
      intervalDelay={0}
      renderer={({
        days,
        hours,
        minutes,
        seconds,
        completed,
        milliseconds,
      }) => {
        if (completed) {
          return <LiveButton />;
        }

        // we wait until 0ms left to start the animations
        if (milliseconds === 0) {
          shouldRender.current = true;
        }

        // day conditions
        const shouldDisplayDays = days > 0;
        setShouldDisplayDays(shouldDisplayDays);
        const shouldDisplayStaticDays =
          !(hours === 0 && minutes === 0 && seconds === 0) &&
          !(hours === 23 && minutes === 59 && seconds === 59);

        // hour conditions
        const shouldDisplayHour =
          (hours > 0 && days >= 0) || (hours === 0 && days > 0);
        setShouldDisplayHours(shouldDisplayHour);
        const shouldDisplayStaticHours =
          !(minutes === 59 && seconds === 59) &&
          !(minutes === 0 && seconds === 0);

        // minute conditions
        const shouldDisplayMinutes = days > 0 || hours > 0 || minutes > 0;
        setShouldDislpayMinutes(shouldDisplayMinutes);

        return (
          <time dateTime={START_DATE.toString()}>
            {shouldRender.current && (
              <Stack direction='row'>
                <Box style={collapseDays} sx={{ willChange: 'width, opacity' }}>
                  <TimeBox color='purple400'>
                    {hours === 23 && minutes === 59 && seconds === 59 && (
                      <AnimatedDigits
                        digit={days}
                        duration='0.5s'
                        unit='Day'
                        iterations='1'
                        segment='first'
                      />
                    )}
                    {hours === 0 && minutes === 0 && seconds === 0 && (
                      <AnimatedDigits
                        digit={days}
                        duration='0.5s'
                        unit='Day'
                        iterations='1'
                        segment='last'
                        delay='0.5s'
                      />
                    )}
                    {shouldDisplayStaticDays && (
                      <AnimatedDigits
                        digit={days}
                        duration='0.5s'
                        unit='Day'
                        iterations='0'
                      />
                    )}
                  </TimeBox>
                </Box>
                <Box
                  style={collapseHours}
                  sx={{ willChange: 'width, opacity' }}
                >
                  <TimeBox color='black300'>
                    {minutes === 59 && seconds === 59 && (
                      <AnimatedDigits
                        digit={hours}
                        duration='0.5s'
                        unit='Hr'
                        iterations='1'
                        segment='first'
                        width={50}
                      />
                    )}
                    {minutes === 0 && seconds === 0 && (
                      <AnimatedDigits
                        digit={hours}
                        duration='0.5s'
                        unit='Hr'
                        iterations='1'
                        segment='last'
                        delay='0.5s'
                        width={50}
                      />
                    )}
                    {shouldDisplayStaticHours && (
                      <AnimatedDigits
                        digit={hours}
                        duration='0.5s'
                        unit='Hr'
                        iterations='0'
                        width={50}
                      />
                    )}
                  </TimeBox>
                </Box>

                <Box
                  style={collapseMinutes}
                  sx={{ willChange: 'width, opacity' }}
                >
                  <TimeBox color='#DBFF00'>
                    {seconds === 0 && (
                      <AnimatedDigits
                        digit={minutes}
                        duration='0.5s'
                        unit='Min'
                        iterations='1'
                        segment='last'
                        delay='0.5s'
                      />
                    )}
                    {seconds === 59 && (
                      <AnimatedDigits
                        digit={minutes}
                        duration='0.5s'
                        unit='Min'
                        iterations='1'
                        segment='first'
                      />
                    )}
                    {seconds >= 1 && seconds <= 58 && (
                      <AnimatedDigits
                        digit={minutes}
                        duration='0.5s'
                        unit='Min'
                        iterations='0'
                      />
                    )}
                  </TimeBox>
                </Box>
                <TimeBox color='purple400'>
                  <AnimatedDigits
                    digit={seconds}
                    duration='1s'
                    unit='Sec'
                    iterations='infinite'
                  />
                </TimeBox>
              </Stack>
            )}
          </time>
        );
      }}
    />
  );
};

export default FlipClock;
