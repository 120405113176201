export default {
  rows: 3,
  isExpanded: [],
  list: [
    {
      name: 'Zach Perret',
      title: 'Co-founder & CEO',
      company: 'Plaid',
      isActive: true,
      isOpen: false,
      description:
        'Zach co-founded Plaid to give developers a data network to power any fintech service. Today, Plaid powers leading financial apps used by millions of people everyday.',
      image:
        'https://images.ctfassets.net/ss5kfr270og3/6zVfAR8wr5N9Rhgf6oKCif/598ccdbf99a682c11bc47b5abb909bbc/Web-Ready_Zach-Perret.jpg',
      imageStyles: {
        top: { xs: '108%', sm: '144%', md: '109%' },
        maxWidth: { xs: '541px', sm: '857px', md: '538px' },
      },
    },
    {
      name: 'Ruchika Gupta Truitt',
      title: 'VP of Product',
      company: 'Esusu',
      isActive: false,
      isOpen: false,
      description:
        "Ruchika is on a mission to utilize technology to improve people's lives by increasing access to basic needs like food, shelter, and financial services.",
      image:
        'https://images.ctfassets.net/ss5kfr270og3/7lpwsSlkQk24LpxV88MCTj/2b68b97c88909d4213700f2032de9f9b/Web-Ready_Ruchika-Gupta-Truitt.jpg',
      imageStyles: {
        top: { xs: '111%', sm: '142%', md: '109%' },
        maxWidth: { xs: '541px', sm: '857px', md: '538px' },
      },
    },
    {
      name: 'Haroon',
      title: 'CEO & Co-founder',
      company: 'Rocket Money',
      isActive: false,
      isOpen: false,
      description:
        'As an early adopter of Plaid, Haroon built and scaled Rocket Money (formerly known as Truebill) from an idea in his basement to a leading personal finance app with over 3.4 million members. ',
      image:
        'https://images.ctfassets.net/ss5kfr270og3/6ezRuHkRgjzenVZfYSfa5b/390d4df081d4b82901644b8a469d73f5/Web-Ready_Haroon_Mokhtarzada.jpg',
      imageStyles: {
        top: { xs: '114%', sm: '146%', md: '114%' },
        maxWidth: { xs: '541px', sm: '857px', md: '538px' },
      },
    },
    {
      name: 'Jean-Denis Greze',
      title: 'CTO',
      company: 'Plaid',
      isActive: false,
      isOpen: false,
      description:
        'Setting product and technical strategy, Jean-Denis is leading the team to build industry-leading tools designed to unlock financial freedom for more users globally. ',
      image:
        'https://images.ctfassets.net/ss5kfr270og3/703b8qtYerDa9nfpHlXpIl/09f49a7dcb558ee3bd93a143c9bfb48f/Web-Ready_Jean-Denis-Greze.jpg',
      imageStyles: {
        top: { xs: '114%', sm: '146%', md: '114%' },
        maxWidth: { xs: '541px', sm: '857px', md: '538px' },
      },
    },
    {
      name: 'Sheila Jambekar',
      title: 'Chief Privacy Officer',
      company: 'Plaid',
      isActive: false,
      isOpen: false,
      description:
        "Sheila is helping build a financial ecosystem that respects consumers' data while empowering them to use that data to improve their financial lives.",
      image:
        'https://images.ctfassets.net/ss5kfr270og3/5z7yS5zHsOlqDNtmqNEgBU/6d7a1e6a4746e697c81e39e0b8bde443/Web-Ready_Sheila-Jambekar.jpg',
      imageStyles: {
        top: { xs: '113%', sm: '146%', md: '111%' },
        maxWidth: { xs: '541px', sm: '857px', md: '538px' },
      },
    },
    {
      name: 'Eric Sager',
      title: 'COO',
      company: 'Plaid',
      isActive: false,
      isOpen: false,
      description:
        'Eric leads the business strategy, operations, and go-to-market teams that are helping companies of all sizes deliver digital financial services.',
      image:
        'https://images.ctfassets.net/ss5kfr270og3/3W1qE2jntG8uVPgSBvuw9/4199e8cee111df68b4275d63b7a29c40/Web-Ready_Eric-Sager.jpg',
      imageStyles: {
        top: { xs: '114%', sm: '146%', md: '114%' },
        maxWidth: { xs: '541px', sm: '857px', md: '538px' },
      },
    },
    {
      name: 'Michelle Boros',
      title: 'Product Lead',
      company: 'Plaid',
      isActive: false,
      isOpen: false,
      description:
        'Michelle innovates products used by companies like SoFi, Mission Lane, BMG Money, and Better to accelerate the lending and consumer decisioning process.',
      image:
        'https://images.ctfassets.net/ss5kfr270og3/2YZtPLrOTTXCUkZO8s782g/70f7451d8569602d04ac6ff65b50f8d0/Web-Ready_Michelle-Boros.jpg',
      imageStyles: {
        top: { xs: '110%', sm: '142%', md: '111%' },
        maxWidth: { xs: '541px', sm: '857px', md: '538px' },
      },
    },
    {
      name: 'Alain Meier',
      title: 'Head of Identity',
      company: 'Plaid',
      isActive: false,
      isOpen: false,
      description: `Alain spent his career building identity and anti-fraud products. Now he's on a path to lower fraud at scale for the digital finance ecosystem.`,
      image:
        'https://images.ctfassets.net/ss5kfr270og3/4KwfvVTVBfp8wkSM1cFM1K/6ef722f08159a8850618d7c539d4e051/Web-Ready_Alain-Meier.jpg',
      imageStyles: {
        top: { xs: '112%', sm: '141%', md: '112%' },
        maxWidth: { xs: '541px', sm: '857px', md: '538px' },
      },
    },
    {
      name: 'Ashley Cornall',
      title: 'Product Manager',
      company: 'Plaid',
      isActive: false,
      isOpen: false,
      description: `Ashley used to drive product strategy at Bain and Google. Now she's at Plaid bringing new products to life and enabling magical experiences for consumers.`,
      image:
        'https://images.ctfassets.net/ss5kfr270og3/1FqL7ELOueBi8MO4Kl9Vv2/bad6c18a233c1b27dbdc9fd876f48975/Web-Ready_Ashley-Cornall.jpg',
      imageStyles: {
        top: { xs: '113%', sm: '150%', md: '111%' },
        maxWidth: { xs: '541px', sm: '857px', md: '538px' },
      },
    },
    {
      name: 'John Anderson',
      title: 'Head of Payments',
      company: 'Plaid',
      isActive: false,
      isOpen: false,
      description: `John leads Plaid's product teams and our efforts to make bank payments smarter, faster, and safer across the digital finance ecosystem.`,
      image:
        'https://images.ctfassets.net/ss5kfr270og3/0ugMSMn51a5uDNtRw7WXE/b1a29d605edd09f4dbf28ef2d71bc83c/Web-Ready_John-Anderson.jpg',
      imageStyles: {
        top: { xs: '105%', sm: '130%', md: '103%' },
        maxWidth: { xs: '541px', sm: '857px', md: '538px' },
      },
    },
  ],
};
