import styled from '@mui/system/styled';
import { keyframes } from '@mui/system';

export const slideUp = keyframes`
  0% {
    bottom: -20px;
    opacity: 0;
  }
  25% {
    bottom: 0px;
    opacity: 1;
  }
  75% {
    bottom: 0px;
    opacity: 1;
  }
  100% {
    bottom: 20px;
    opacity: 0;
  }
`;

export const slideUpFast = keyframes`
  0% {
    bottom: -20px;
    opacity: 0;
  }
  35% {
    bottom: 0px;
    opacity: 1;
  }
  65% {
    bottom: 0px;
    opacity: 1;
  }
  100% {
    bottom: 20px;
    opacity: 0;
  }
`;

export const midToTop = keyframes`
  0% {
    bottom: 0px;
    opacity: 1;
  }
  100% {
    bottom: 20px;
    opacity: 0;
  }
`;

export const midToTopFast = keyframes`
  0% {
    bottom: 0px;
    opacity: 1;
  }
  75% {
    bottom: 20px;
    opacity: 0;
  }
  100% {
    bottom: 20px;
    opacity: 0;
  }
`;

export const bottomToMid = keyframes`
  0% {
    bottom: -20px;
    opacity: 0;
  }
  75% {
    bottom: 0px;
    opacity: 1;
  }
  100% {
    bottom: 0px;
    opacity: 1;
  }
`;

export const bottomToMidFast = keyframes`
  0% {
    bottom: -20px;
    opacity: 0;
  }
  100% {
    bottom: 0px;
    opacity: 1;
  }
`;

interface IDigitAnimation {
  duration: string;
  iterations: string;
  delay: string;
  segment: string;
}

export const Hundredth = styled('span')<IDigitAnimation>(
  ({ duration, iterations, delay, segment }) => {
    const animationMap = {
      default: slideUpFast,
      last: midToTopFast,
      first: bottomToMidFast,
    };
    return {
      display: 'block',
      position: 'absolute',
      animation: `${animationMap[segment]} ${duration} ${iterations} ease-in-out`,
      animationDelay: delay,
    };
  },
);

export const Tenth = styled('span')<IDigitAnimation>(
  ({ duration, iterations, delay, segment }) => {
    const animationMap = {
      default: slideUp,
      last: midToTop,
      first: bottomToMid,
    };
    return {
      display: 'block',
      position: 'absolute',
      left: '10px',
      animation: `${animationMap[segment]} ${duration} ${iterations} ease-in-out`,
      animationDelay: delay,
    };
  },
);
