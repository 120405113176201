import { useState, useCallback } from 'react';
import { AgendaItem } from './Agenda.types';

const useAgenda = (
  initialState: Array<AgendaItem>,
): {
  toggleAgendaItem: (string, boolean?) => void;
  agenda: Array<AgendaItem>;
} => {
  const [state, setState] = useState({
    agenda: initialState,
  });

  const toggleAgendaItem = useCallback((title, forceOpen = false) => {
    setState((state) => {
      return {
        agenda: state.agenda.map((item) => {
          if (item.title === title) {
            return { ...item, isOpen: forceOpen ? true : !item.isOpen };
          } else if (item?.items?.length > 0) {
            return {
              ...item,
              items: item.items.map((item) => {
                if (item.title === title) {
                  return { ...item, isOpen: forceOpen ? true : !item.isOpen };
                }
                return { ...item };
              }),
            };
          }

          return { ...item };
        }),
      };
    });
  }, []);

  return { toggleAgendaItem, agenda: state.agenda };
};

export default useAgenda;
