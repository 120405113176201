import * as React from 'react';
import ArrowRight from 'plaid-threads/Icons/ArrowRight';
import ArrowDown from 'plaid-threads/Icons/ArrowDown';
import ArrowUp from 'plaid-threads/Icons/ArrowUp';
import styled from '@mui/system/styled';
import { animated, easings, useSprings } from '@react-spring/web';
import {
  Box as VBox,
  Section,
  Container,
  Row,
  Column,
  Stack,
} from 'src/components-v2/Layout';
import { Typography } from 'src/components-v2/DataDisplay';
import SpeakerCard from 'src/sections/ThreadsEvent/23/Speakers/SpeakerCard';
import { Image } from 'src/components-v2/Media';
import { useDeviceSize } from 'src/hooks';
import { ThreadsEventButton } from '../ThreadsEventButton';
import { ISpeakers } from './Speakers.types';
import { ConditionalWrapper } from 'src/components/helpers';

// we split the array of speakers into chunks and back load the last chunks
// so that the first row of speakers displays larger cards
const makeSpeakerRows = (list, rows) => {
  let remainder = list.length % rows;
  const chunks = [];
  const evenArray = list.slice(0, list.length - remainder);
  if (evenArray.length % rows === 0) {
    const chunkSize = evenArray.length / rows;
    let start = -chunkSize;
    let end = 0;
    for (let i = 0; i < rows; i++) {
      start += chunkSize;
      end += chunkSize;
      chunks[i] = evenArray.slice(start, end);
    }
  }

  // add remaining items to last chunks
  for (remainder; remainder > 0; remainder -= 1) {
    chunks[chunks.length - remainder].push(list[list.length - remainder]);
  }
  return chunks;
};

const StyledArrow = styled(ArrowRight)(() => {
  return { width: '1.6rem !important', height: '1.6rem !important' };
});
const Box = animated(VBox);
const backgroundImage =
  'https://images.ctfassets.net/ss5kfr270og3/6Sy5IDlOjYwPUBD2vUNz2E/ed227fdd520f1b6733e5e9d9a0fce44e/threads-event-texture_2x.png';

const Speakers: React.FunctionComponent<ISpeakers> = ({
  handleActivateCard,
  handleMobileCardReveal,
  handleOpenCard,
  isExpanded,
  list,
  rows,
  title,
}) => {
  const chunks = makeSpeakerRows(list, rows);
  const device = useDeviceSize();
  const deviceCache = React.useRef(null);
  const isMobileCardsVisible = isExpanded.every((card) => {
    return card === true;
  });

  React.useEffect(() => {
    const expandedColumns = Array.from(Array(rows - 1)).map(() => {
      return device.isLarge;
    });
    if (deviceCache.current === null) {
      deviceCache.current = device.isLarge;
      // when viewed on a small device we collapse all columns
      handleMobileCardReveal(expandedColumns);
    } else if (deviceCache.current !== device.isLarge) {
      deviceCache.current = device.isLarge;
      // when viewed on a large device we expand all the columns
      handleMobileCardReveal(expandedColumns);
    }
  });

  const [springs] = useSprings(
    rows - 1,
    (index) => {
      return {
        from: { height: '0' },
        to: { height: isExpanded[index] ? '100%' : '0' },
        config: {
          duration: 600,
          easing: easings.easeInQuad,
        },
      };
    },
    [isExpanded],
  );

  return (
    <Section>
      <Container>
        <Row>
          <Column xl={18} xlOffset={3} xs={24}>
            <Box
              sx={{
                border: 'solid 1px #111111',
                p: '3.4rem 3.2rem 4.4rem 3.2rem',
                borderRadius: '20px',
                position: 'relative',
                overflow: 'hidden',
              }}
            >
              <Image
                sx={{
                  position: 'absolute',
                  top: { xs: '-20px', sm: '-90px' },
                  right: { xs: '-140px', sm: '-260px' },
                  transform: 'rotate(8.14deg)',
                }}
                src={backgroundImage + '?fm=webp&q=1'}
                alt='spray paint'
                width='774'
                height='246'
                sources={[
                  {
                    media: '(max-width: 639px)',
                    srcSet: backgroundImage + '?fm=webp&q=1',
                  },
                  {
                    media: '(min-width: 640px)',
                    srcSet: backgroundImage + '?fm=webp&q=1',
                  },
                ]}
              />
              <Stack
                direction='row'
                sx={{
                  alignItems: 'center',
                  mb: '3.8rem',
                  justifyContent: { sm: 'left', xs: 'center' },
                }}
              >
                <Stack
                  sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    bgcolor: 'purple400',
                    borderRadius: '50%',
                    width: '48px',
                    height: '48px',
                    marginRight: '1.2rem',
                    border: 'solid 1px #111111',
                    boxSizing: 'border-box',
                    padding: '1.4rem 1.4rem 1.5rem 1.5rem',
                    display: { xs: 'none', sm: 'block' },
                  }}
                >
                  <StyledArrow />
                </Stack>
                <Typography
                  variant='h3'
                  component='h2'
                  theme='threads-event-23'
                  sx={{ mb: { xs: 0 }, display: { xs: 'none' } }}
                >
                  {title}
                </Typography>
                <Typography
                  variant='h2'
                  component='h2'
                  theme='threads-event-23'
                  sx={{ mb: { xs: 0 }, display: { xs: 'block' } }}
                >
                  {title}
                </Typography>
              </Stack>
              {chunks.map((chunk, row) => {
                return (
                  <ConditionalWrapper
                    key={`wrapper-box-${row}`}
                    condition={row > 0}
                    wrapper={(children) => {
                      return (
                        <Box
                          sx={{ overflow: 'hidden', willChange: 'height' }}
                          style={springs[row - 1]}
                          key={`speaker-box-${row}`}
                        >
                          {children}
                        </Box>
                      );
                    }}
                  >
                    <Stack
                      component='ul'
                      direction='row'
                      sx={{
                        flexDirection: {
                          xs: 'column',
                          sm: 'column',
                          md: 'row',
                        },
                        m: '0 !important',
                      }}
                      key={`speaker-stack-${row}`}
                    >
                      {chunk.map((speaker, column) => {
                        return (
                          <SpeakerCard
                            name={speaker.name}
                            title={speaker.title}
                            company={speaker.company}
                            description={speaker.description}
                            isActive={speaker.isActive}
                            isOpen={speaker.isOpen}
                            onClick={() => {
                              return handleOpenCard(speaker.name);
                            }}
                            onHover={() => {
                              return handleActivateCard(speaker.name);
                            }}
                            key={`speaker-card-${row}-${column}`}
                            image={speaker.image}
                            device={device}
                            imageStyles={speaker.imageStyles}
                          />
                        );
                      })}
                    </Stack>
                  </ConditionalWrapper>
                );
              })}
              <Image
                sx={{
                  position: 'absolute',
                  bottom: { xs: '-40px', sm: '-70px' },
                  left: { xs: '-110px', sm: '-120px' },
                  transform: 'rotate(-170.85deg)',
                  zIndex: -1,
                }}
                src={backgroundImage + '?fm=webp&q=100'}
                alt='spray paint'
                width='774'
                height='246'
                sources={[
                  {
                    media: '(max-width: 639px)',
                    srcSet: backgroundImage + '?fm=webp&q=100',
                  },
                  {
                    media: '(min-width: 640px)',
                    srcSet: backgroundImage + '?fm=webp&q=100',
                  },
                ]}
              />
            </Box>
            <Stack
              alignItems={'center'}
              sx={{
                mt: '-40px',
                zIndex: 1,
                pt: '1.2rem',
                position: 'relative',
                visibility: {
                  md: 'hidden',
                },
              }}
            >
              <ThreadsEventButton
                size='small'
                color='purple'
                onClick={handleMobileCardReveal}
                sx={{
                  border: 'solid 1px black',
                }}
              >
                {!isMobileCardsVisible && (
                  <>
                    See more <ArrowDown />
                  </>
                )}
                {isMobileCardsVisible && (
                  <>
                    See less <ArrowUp />
                  </>
                )}
              </ThreadsEventButton>
            </Stack>
          </Column>
        </Row>
      </Container>
    </Section>
  );
};

export default Speakers;
