import React, { FC, useState, useEffect } from 'react';
import { VideoOverlayProps, HeroWithVideoProps } from './HeroWithVideo.types';
import { Player as LottiePlayer } from '@lottiefiles/react-lottie-player';

import { Typography } from 'src/components-v2/DataDisplay';
import { Box, Column, Container, Row, Section } from 'src/components-v2/Layout';
import { PlayButton } from 'src/components-v2/Inputs';
import { AnimatedVideo, Image, VideoPlayer } from 'src/components-v2/Media';
import { usePaywallProvider } from 'src/components/Surfaces';
import { WatchNowButton } from 'src/sections/ThreadsEvent';

const BackgroundImage = {
  src:
    'https://images.ctfassets.net/ss5kfr270og3/7d3iinpcRprTBoaOMXlvNH/e0075921a7480d2742e32822febeee88/threads-white-texture.png',
  desktopImg: '?fm=webp&w=1578&h=500&fit=fill&q=1',
  mobileImg: '?fm=webp&w=640&h=202&fit=fill&q=1',
};

const BackgroundVideo = () => {
  return (
    <AnimatedVideo
      sx={{
        zIndex: 3,
        position: 'absolute',
        top: '0',
        left: '50%',
        transform: 'translateX(-50%)',
        objectFit: 'cover',
      }}
      isLazy={false}
      width='100%'
      height='100%'
      sources={[
        {
          src:
            'https://videos.ctfassets.net/ss5kfr270og3/1zEdnk3YmFpFS61bu7jWtm/687a74ee3fae09030eae3ed07700007f/Post_Event_Header_Loop.mp4',
          type: 'video/mp4',
        },
        {
          src:
            'https://videos.ctfassets.net/ss5kfr270og3/3L7GaC19abykFdszNpBm7g/6b062aeeb36408b314b4e2f2e7470d80/Post_Event_Header_Loop.webm',
          type: 'video/webm',
        },
      ]}
    />
  );
};

const VideoOverlay: FC<VideoOverlayProps> = ({
  heading,
  description,
  image,
  handlePlay,
}) => {
  const {
    setIsModalOpen,
    isContentUnlocked,
    hasPaywall,
  } = usePaywallProvider();

  return (
    <Section
      component='header'
      sx={{
        position: 'absolute',
        zIndex: 3,
        top: '0',
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        pt: { xl: '17.9rem' },
      }}
    >
      <Container sx={{ px: { xs: '0', sm: '5rem' } }}>
        <Row>
          <Column xs={24} sm={22} smOffset={1}>
            <Row
              sx={{
                flexDirection: { xs: 'column', xl: 'row' },
                alignItems: { xs: 'left', xl: 'center' },
                justifyContent: { xs: 'center', xl: 'left' },
                border: { sm: '1px solid white' },
                borderRadius: { sm: '20px' },
                backgroundColor: 'rgba(0, 0, 0, 0.8)',
                height: { xs: '650px', sm: 'auto' },
                pt: { xs: '1rem', sm: '5rem' },
                pb: { xs: '1rem', sm: '5rem' },
              }}
            >
              <Column
                xs={22}
                xsOffset={1}
                md={20}
                mdOffset={2}
                lg={22}
                lgOffset={1}
                xl={16}
              >
                <Image
                  isLazy={false}
                  src={image.src + '?fm=webp&w=726&h=88&fit=fill&q=60'}
                  alt={image.alt}
                  width='363'
                  height='44'
                  sx={{ mb: { xs: '1.9rem', sm: '2.5rem', lg: '1rem' } }}
                  sources={[
                    {
                      media: '(max-width: 639px)',
                      srcSet: `${image.src + '?fm=webp&h=72&fit=fill&q=60'}`,
                    },
                    {
                      media: '(min-width: 640px)',
                      srcSet: `${
                        image.src + '?fm=webp&w=726&h=88&fit=fill&q=60'
                      }`,
                    },
                  ]}
                />
                <Typography
                  variant='h1'
                  theme='post-threads-hero'
                  sx={{ color: 'white' }}
                >
                  {heading}
                </Typography>
                <Typography
                  variant='p'
                  theme='post-threads-hero'
                  sx={{
                    color: 'white',
                    mb: { xs: '2rem' },
                  }}
                >
                  {description}
                </Typography>
              </Column>
              <Column
                xs={6}
                xsOffset={1}
                sm={4}
                md={3}
                mdOffset={2}
                lg={2}
                lgOffset={2}
                sx={{ margin: '0 auto !important', width: 'auto !important' }}
              >
                {hasPaywall && isContentUnlocked && (
                  <PlayButton onClick={handlePlay} />
                )}
                {hasPaywall && !isContentUnlocked && (
                  <WatchNowButton
                    onClick={() => {
                      return setIsModalOpen(true);
                    }}
                  />
                )}
              </Column>
            </Row>
          </Column>
        </Row>
      </Container>
    </Section>
  );
};

const HeroWithVideo: FC<HeroWithVideoProps> = ({
  heading,
  description,
  image,
  videoUrl,
}) => {
  const [isVisible, setIsVisible] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const [animationData, setAnimationData] = useState<Record<string, unknown>>();

  useEffect(() => {
    import('../../Agenda/BlackThreadsAnimationIn.json').then(setAnimationData);
  }, []);

  const handlePlay = () => {
    setIsVisible(false);
    setIsPlaying(true);
  };

  return (
    <>
      <Box
        sx={{
          position: 'relative',
          overflow: 'hidden',
          maxHeight: { xs: '645px', sm: '600px', lg: '700px', xl: '800px' },
        }}
      >
        <VideoPlayer
          isLazy={false}
          sx={{
            zIndex: 2,
            pt: '0',
            height: { xs: '645px', sm: '600px', lg: '700px', xl: '800px' },
          }}
          url={videoUrl}
          light={false}
          playing={isPlaying}
          muted={!isPlaying}
          tabIndex={isPlaying ? 0 : -1}
        />
        {isVisible && <BackgroundVideo />}
        {isVisible && (
          <VideoOverlay
            heading={heading}
            description={description}
            image={image}
            handlePlay={handlePlay}
          />
        )}
      </Box>
      <Box
        sx={{
          position: 'absolute',
          zIndex: 1,
          top: { sm: '650px', md: '600px', lg: '680px', xl: '760px' },
          left: { sm: '-100px', md: '-210px' },
          overflow: 'hidden',
          display: { xs: 'none', sm: 'block' },
        }}
      >
        <LottiePlayer
          src={animationData}
          autoplay={true}
          loop={false}
          keepLastFrame={true}
        />
      </Box>
      <Box sx={{ position: 'relative' }}>
        <Image
          sx={{
            position: 'absolute',
            right: '0',
            top: { xs: '-15px', sm: '-25px' },
          }}
          isLazy={false}
          width='789px'
          height='250px'
          src={BackgroundImage.src + BackgroundImage.desktopImg}
          alt=''
          sources={[
            {
              media: '(max-width: 639px)',
              srcSet: `${BackgroundImage.src + BackgroundImage.mobileImg}`,
            },
            {
              media: '(min-width: 640px)',
              srcSet: `${BackgroundImage.src + BackgroundImage.desktopImg}`,
            },
          ]}
        />
      </Box>
    </>
  );
};

export default HeroWithVideo;
