import React from 'react';
import { Hundredth, Tenth } from './FlipClock.styled';
import { getNumberPlace, hasLeadingZero } from './flip-clock-utils';
import { Box } from 'src/components-v2/Layout';

interface IAnimatedDigits {
  digit: number;
  duration: string;
  unit: string;
  segment?: string;
  iterations: string;
  delay?: string;
  width?: number;
}

const AnimatedDigits: React.FC<IAnimatedDigits> = ({
  digit,
  duration,
  unit,
  segment = 'default',
  iterations,
  delay = '0',
  width = 61,
}) => {
  const [hundredth, tenth] = getNumberPlace(digit);
  return (
    <Box
      component='span'
      sx={{
        display: 'block',
        overflow: 'hidden',
        position: 'relative',
        width: width,
      }}
    >
      <Hundredth
        duration={duration}
        iterations={iterations}
        delay={delay}
        segment={segment}
      >
        {hasLeadingZero(digit)}
        {hundredth}
      </Hundredth>
      <Tenth
        duration={duration}
        iterations={iterations}
        delay={delay}
        segment={segment}
      >
        {tenth}
      </Tenth>{' '}
      <Box component='span' sx={{ left: '24px', position: 'relative' }}>
        {unit}s
      </Box>
    </Box>
  );
};

export default AnimatedDigits;
