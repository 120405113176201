import React from 'react';

import { Image } from 'src/components-v2/Media';

const bgImageSrc =
  'https://images.ctfassets.net/ss5kfr270og3/6Sy5IDlOjYwPUBD2vUNz2E/ed227fdd520f1b6733e5e9d9a0fce44e/threads-event-texture_2x.png';

const MarqueeBackgroundImage = ({ styles }) => {
  return (
    <Image
      sx={{
        position: 'absolute',
        zIndex: -1,
        maxWidth: 'none',
        width: { xs: '404px', md: '503px', lg: '670px' },
        height: { xs: '129px', md: '159px', lg: '212px' },
        ...styles,
      }}
      src={bgImageSrc + '?fm=webp&q=1'}
      alt=''
      width='100%'
      height='100%'
    />
  );
};
export default MarqueeBackgroundImage;
