import { SxProps } from '@mui/system';
import React from 'react';

interface Speaker {
  name: string;
  title: string;
  company: string;
  isActive: boolean;
  isOpen: boolean;
  description: string;
  image: string;
  imageStyles: SxProps;
}
interface SpeakerState {
  rows: number;
  isExpanded: Array<boolean>;
  list: Array<Speaker>;
}

interface UseSpeakers {
  state: SpeakerState;
  showMoreSpeakerCards: (arr: Array<boolean>) => void;
  toggleOpenSpeakerCard: (speakerId: string) => void;
  toggleActiveSpeakerCard: (speakerId: string) => void;
  shouldFreezeSpeakerState: (shouldFreeze: boolean) => void;
}

const useSpeakers = (initialState: SpeakerState): UseSpeakers => {
  const isStateFrozen = React.useRef(false);
  const [state, setState] = React.useState(initialState);

  const shouldFreezeSpeakerState = (val) => {
    isStateFrozen.current = val;
  };

  const toggleOpenSpeakerCard = (name, forceOpen = false) => {
    if (!isStateFrozen.current) {
      setState((prevState) => {
        return {
          ...prevState,
          list: prevState.list.map((speaker) => {
            if (speaker.name === name) {
              return {
                ...speaker,
                isOpen: forceOpen ? true : !speaker.isOpen,
                isActive: true,
              };
            }
            return { ...speaker, isOpen: false, isActive: false };
          }),
        };
      });
    }
  };

  const toggleActiveSpeakerCard = (name) => {
    if (!isStateFrozen.current) {
      setState((prevState) => {
        return {
          ...prevState,
          list: prevState.list.map((speaker) => {
            if (speaker.name === name) {
              return { ...speaker, isActive: true };
            }
            return { ...speaker, isActive: false, isOpen: false };
          }),
        };
      });
    }
  };

  const handleMobileCardChange = (isExpanded) => {
    setState((prevState) => {
      return {
        ...prevState,
        isExpanded,
      };
    });
  };

  const showMoreSpeakerCards = (forceOpen) => {
    // we force the card columns to expand when
    // a device changes from small to large
    if (forceOpen) {
      handleMobileCardChange(forceOpen);
      return;
    }

    // once all cards are expanded we collapse all the cards on the next click
    const expandedCards = [...state.isExpanded];
    if (
      expandedCards.every((card) => {
        return card === true;
      })
    ) {
      const collapsedCards = Array.from(Array(state.rows - 1)).map(() => {
        return false;
      });
      handleMobileCardChange(collapsedCards);
      return;
    }

    // we expand one row of cards with each click
    for (let i = 0; i < expandedCards.length; i++) {
      if (!expandedCards[i]) {
        expandedCards[i] = true;
        handleMobileCardChange(expandedCards);
        break;
      }
    }
  };

  return {
    state,
    showMoreSpeakerCards,
    toggleOpenSpeakerCard,
    toggleActiveSpeakerCard,
    shouldFreezeSpeakerState,
  };
};

export default useSpeakers;
