import * as React from 'react';
import ChevronUpS1 from 'plaid-threads/Icons/ChevronUpS1';
import { animated, useSpring, easings } from '@react-spring/web';
import useMeasure from 'react-use-measure';
import { Element } from 'react-scroll';

import { Box as VBox, Stack as VStack } from 'src/components-v2/Layout';
import { Typography as VTypography } from 'src/components-v2/DataDisplay';
import { Image as VImage } from 'src/components-v2/Media';
import { colors } from 'src/components-v2/theme';

import { ISpeakerCardProps } from './SpeakerCard.types';

const Stack = animated(VStack);
const Box = animated(VBox);
const Typography = animated(VTypography);
const Image = animated(VImage);

const DESKTOP_IMAGE = '?fm=webp&w=1076&q=75';
const TABLET_IMAGE = '?fm=webp&w=1714&q=75';
const MOBILE_IMAGE = '?fm=webp&w=1082&q=75';

const SpeakerCard: React.FunctionComponent<ISpeakerCardProps> = ({
  company,
  description,
  onClick,
  onHover,
  image,
  isActive = false,
  isOpen = false,
  name,
  title,
  device,
  imageStyles,
}) => {
  const [cardRef, cardRefBounds] = useMeasure();
  const speakerCardAnimation = useSpring({
    from: {
      width: !device.isLarge ? '100%' : '1px',
    },
    to: {
      width: !device.isLarge ? '100%' : isActive ? '171px' : '1px',
    },
    config: {
      duration: 200,
      easing: easings.easeInCubic,
    },
  });

  const drawerAnimation = useSpring({
    from: {
      transform: 'translate(0px, 0px)',
    },
    to: {
      transform: isOpen
        ? `translate(0px, -${cardRefBounds.height - 220}px)`
        : 'translate(0px, 0px)',
    },
    config: {
      duration: 200,
      easing: easings.easeInCubic,
    },
  });

  const fadeInOnOpen = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: isOpen ? 1 : 0,
    },
    config: {
      duration: 200,
      easing: easings.easeInCubic,
    },
  });

  const colorTransition = useSpring({
    from: {
      background: colors.black300,
    },
    to: {
      background: isActive ? '#DBFF00' : colors.black300,
    },
    config: {
      duration: 200,
      easing: easings.easeInCubic,
    },
  });

  const grayscaleAnimation = useSpring({
    from: {
      filter: 'grayscale(1)',
    },
    to: {
      filter: isActive ? 'grayscale(0)' : 'grayscale(1)',
    },
    config: {
      duration: 200,
      easing: easings.easeInCubic,
    },
  });

  return (
    <Stack
      component='li'
      style={speakerCardAnimation}
      onClick={onClick}
      onMouseOver={onHover}
      onFocus={onHover}
      onKeyDown={onClick}
      direction='column'
      tabIndex={0}
      sx={{
        flexGrow: 1,
        cursor: 'pointer',
        userSelect: 'none',
        height: '521px',
        overflow: 'hidden !important',
        position: 'relative',
        transform: 'scale(1, 1)',
        willChange: 'width',
      }}
    >
      <Box
        sx={{
          borderRadius: '20px',
          border: 'solid 1px #111111',
          minHeight: '320px',
          position: 'relative',
          overflow: 'hidden !important',
          zIndex: 0,
        }}
      >
        <Element name={'speaker-' + name}>
          <Image
            sx={{
              position: 'absolute',
              zIndex: 0,
              left: '50%',
              transform: 'translate(-50%, -50%)',
              willChange: 'filter',
              borderRadius: '20px',
              ...imageStyles,
            }}
            style={grayscaleAnimation}
            alt={`${name} - ${title}, ${company}`}
            src={image}
            width={857}
            height={2570}
            sources={[
              {
                media: '(max-width: 639px)',
                srcSet: image + MOBILE_IMAGE,
              },
              {
                media: '(max-width: 1023px)',
                srcSet: image + TABLET_IMAGE,
              },
              {
                media: '(min-width: 1024px)',
                srcSet: image + DESKTOP_IMAGE,
              },
            ]}
          />
        </Element>
      </Box>
      <Box
        style={{ ...drawerAnimation, ...colorTransition }}
        sx={{
          border: 'solid 1px #111111',
          borderBottom: 'none',
          borderRadius: '20px 20px 0 0',
          flexGrow: 1,
          padding: '2rem 2.0rem 2.8rem',
          position: 'relative',
          zIndex: 1,
          transform: 'translate(0px, 0px)',
          willChange: 'background',
        }}
        ref={cardRef}
      >
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <Typography
            variant='h5'
            component='h3'
            theme='threads-event-23'
            sx={{ maxWidth: { xs: '205px', sm: '100%' } }}
          >
            {name}
          </Typography>
          <DrawerButton isActive={isActive} isOpen={isOpen} device={device} />
        </Stack>
        <Typography
          variant='h6'
          component='h4'
          theme='threads-event-23'
          sx={{
            fontWeight: '400',
            marginBottom: '2.4rem',
          }}
        >
          {title}, {company}
        </Typography>
        <Box style={fadeInOnOpen}>
          <Typography
            variant='p'
            theme='threads-event-23'
            sx={{ pb: '1.2rem' }}
          >
            {description}
          </Typography>
        </Box>
      </Box>
      <Box
        style={colorTransition}
        sx={{
          border: 'solid 1px #111111',
          borderRadius: '0 0 20px 20px',
          borderTop: 'none',
          top: 504,
          flexGrow: 1,
          position: 'absolute',
          width: '100%',
          zIndex: 2,
          height: 17,
          willChange: 'background',
        }}
      />
      <Box
        sx={{
          bgcolor: 'black300',
          top: 505,
          flexGrow: 1,
          position: 'absolute',
          width: '100%',
          zIndex: 1,
          height: 27,
        }}
      />
    </Stack>
  );
};

const DrawerButton = ({ isActive, isOpen, device }) => {
  const drawerButtonAnimation = useSpring({
    from: {
      transform: 'rotate(0deg)',
      opacity: !device?.isLarge ? 0 : 1, // display button on smaller devices
    },
    to: {
      transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
      opacity: !device?.isLarge ? 1 : isActive ? 1 : 0,
    },
    config: {
      duration: 200,
      easing: easings.easeInCubic,
    },
  });

  return (
    <Stack
      style={drawerButtonAnimation}
      sx={{
        alignItems: 'center',
        bgcolor: 'white',
        border: 'solid 1px #111111',
        borderRadius: '50%',
        boxSizing: 'border-box',
        height: '44px',
        justifyContent: 'center',
        minWidth: '44px',
        position: 'absolute',
        right: '1.2rem',
        top: '1.8rem',
      }}
    >
      <ChevronUpS1 />
    </Stack>
  );
};

export default SpeakerCard;
