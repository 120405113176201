import React, { FC } from 'react';
import { MenuItemProps } from './MainMenu.types';

// components
import { Stack } from 'src/components-v2/Layout';

const MenuItem: FC<MenuItemProps> = ({ children, sx }) => {
  return (
    <Stack component='li' sx={{ flexDirection: 'row', ...sx }}>
      {children}
    </Stack>
  );
};

export default MenuItem;
