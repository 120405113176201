export const isPlural = (num: number): string => {
  return num > 1 ? 's' : '';
};

export const hasLeadingZero = (num: number): string => {
  return num < 10 ? '0' : '';
};

export const getNumberPlace = (number: number): Array<string> => {
  const [hundredth, tenth] = String(number).split('');
  if (String(number).length > 1) {
    return [hundredth, tenth];
  }
  return [, hundredth];
};
