import React, { FC } from 'react';
import styled from '@mui/system/styled';
import { animated, useSpring, easings } from '@react-spring/web';
import useMeasure from 'react-use-measure';

import ChevronDownS1 from 'plaid-threads/Icons/ChevronDownS1';
import { nanoid } from 'nanoid';

import { Typography } from 'src/components-v2/DataDisplay';
import {
  Row,
  Column,
  Stack as VStack,
  Box as VBox,
} from 'src/components-v2/Layout';
import { IAccordionItem } from './AccordionItem.types';

const Box = animated(VBox);
const Stack = animated(VStack);
const AccordionToggleButton = styled('button')(() => {
  return {
    display: 'block',
    width: '100%',
    padding: '2.4rem 0',
  };
});

const StyledChevron = styled(ChevronDownS1)(() => {
  return { color: 'black !important' };
});

const AccordionItem: FC<IAccordionItem> = ({
  title,
  content,
  isOpen,
  onClick,
}) => {
  const [itemRef, itemRefBounds] = useMeasure();

  const itemAnimation = useSpring({
    from: {
      height: 0,
    },
    to: {
      height: isOpen ? itemRefBounds.height : 0,
    },
    config: {
      duration: 150,
      easing: easings.easeInQuad,
    },
  });

  const caretAnimation = useSpring({
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: isOpen ? 'rotate(-180deg)' : 'rotate(0deg)',
    },
    config: {
      duration: 150,
      easing: easings.easeInQuad,
    },
  });

  const accordionId = `accordion-${nanoid()}`;

  return (
    <>
      <Row sx={{ borderTop: '1px solid black' }}>
        <Column xs={24}>
          <AccordionToggleButton
            onClick={() => {
              return onClick(title);
            }}
            aria-expanded={isOpen ? 'true' : 'false'}
            aria-controls={accordionId}
            type='button'
            id={`${accordionId}-control`}
          >
            <Stack
              direction='row'
              justifyContent='space-between'
              alignItems='center'
              sx={{
                width: '100%',
              }}
            >
              <Typography
                variant='h4'
                component='h3'
                theme='threads-event-23'
                sx={{ textAlign: 'left', mb: 0 }}
              >
                {title}
              </Typography>

              <Stack>
                <Box sx={{ height: '24px' }} style={caretAnimation}>
                  <StyledChevron />
                </Box>
              </Stack>
            </Stack>
          </AccordionToggleButton>
        </Column>
      </Row>
      <Row>
        <Column xs={23} sm={20}>
          <Box
            component='dd'
            aria-labelledby={`${accordionId}-control`}
            id={accordionId}
            role='region'
            style={itemAnimation}
            sx={{ overflowY: 'hidden', willChange: 'height' }}
          >
            <Typography
              variant='p'
              theme='threads-event-23'
              sx={{ mb: 0, pb: '2.4rem', textAlign: 'left' }}
              ref={itemRef}
            >
              {content}
            </Typography>
          </Box>
        </Column>
      </Row>
    </>
  );
};

export default AccordionItem;
