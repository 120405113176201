import React from 'react';
import Script from 'next/script';
import styled from '@mui/system/styled';

import { AccordionItem } from '../AccordionItem';
import { Row, Column, Section, Container } from 'src/components-v2/Layout';
import { Typography } from 'src/components-v2/DataDisplay';

const FaqQuestionsList = styled('dl')(() => {
  return {
    margin: '0',
    listStyle: 'none',
    '& > :last-child': {
      borderBottom: '1px solid black',
    },
  };
});

const generateStructuredData = (faqItems): Record<string, unknown> => {
  const schemaItems = faqItems.map((item) => {
    return {
      '@type': 'Question',
      name: item.title,
      acceptedAnswer: {
        '@type': 'Answer',
        text: item.content,
      },
    };
  });

  return {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: [...schemaItems],
  };
};

const title = 'FAQ';

const items = [
  {
    title: 'What is Threads 2023?',
    content: `Threads 2023 is a virtual event for those curious about the future of digital finance. During this event, attendees will hear about Plaid’s latest products and industry trends through a series of keynotes and demos designed to help you build your business.`,
    isOpen: true,
  },
  {
    title: 'Who should attend?',
    content:
      'You should! Whether you’re a developer, early stage founder, or global enterprise leader, Threads 2023 will have something for everyone–even those just curious about the future of finance.',
    isOpen: false,
  },
  {
    title: 'Can I attend internationally?',
    content:
      'Yes, Threads 2023 is designed for a global audience and will be attended by friends, customers, and innovators from across the globe.',
    isOpen: false,
  },
  {
    title: 'How do I register?',
    content: (
      <>
        <a href='https://threads.plaid.com/begin'>
          Click here to register online
        </a>
        . It will take you to a registration form. Once you complete and submit
        the form, your spot will be secured and you will receive an email
        confirmation from Plaid. If you run into any issues or have any
        questions about your registration, please contact{' '}
        <a href='mailto:threads@plaid.com'>threads@plaid.com</a>.
      </>
    ),
    isOpen: false,
  },
  {
    title: 'Will recordings be available afterwards?',
    content: `Yes, on-demand content will be available on this page following the event on June 22nd.`,
    isOpen: false,
  },
];

const Faq = () => {
  const structuredData = generateStructuredData(items);
  const [state, setState] = React.useState(items);

  const handleToggleItem = (title) => {
    setState((prevState) => {
      return prevState.map((item) => {
        if (item.title === title) {
          return {
            ...item,
            isOpen: item.isOpen ? false : true,
          };
        }
        return {
          ...item,
          isOpen: false,
        };
      });
    });
  };

  return (
    <>
      <Section
        sx={{
          position: 'relative',
          width: '100vw',
          px: 0,
        }}
      >
        <Container>
          <Row>
            <Column xs={24} xl={18} xlOffset={3}>
              <Typography
                theme='threads-event-23'
                variant='h2'
                sx={{
                  pb: { xs: '2rem', sm: '4.4rem' },
                  textAlign: { xs: 'center', sm: 'left' },
                }}
              >
                {title}
              </Typography>

              <FaqQuestionsList
                aria-label={`${title} Accordion Control Group Buttons`}
              >
                {state.map((content, i) => {
                  return (
                    <AccordionItem
                      key={`content-${i}`}
                      {...content}
                      onClick={handleToggleItem}
                    />
                  );
                })}
              </FaqQuestionsList>
            </Column>
          </Row>
        </Container>
      </Section>
      <Script
        id='faq-schema'
        strategy='lazyOnload'
        type='application/ld+json'
        dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredData) }}
      />
    </>
  );
};

export default Faq;
