import React, { useState } from 'react';
import styled from '@mui/system/styled';
import { Element } from 'react-scroll';

import { Player } from '@lottiefiles/react-lottie-player';
import { create } from '@lottiefiles/lottie-interactivity';

import {
  Section,
  Row,
  Container,
  Column,
  Stack,
  Box,
} from 'src/components-v2/Layout';
import { Typography } from 'src/components-v2/DataDisplay';
import { AgendaItem } from './AgendaItem';
import { IAgenda } from './Agenda.types';
import BlackThreadsAnimationIn from './BlackThreadsAnimationIn.json';

const AgendaList = styled('dl')(() => {
  return {
    width: '100%',
    border: '1px solid black',
    borderRadius: '20px',
    zIndex: 1,
    margin: '0',
    listStyle: 'none',
    overflow: 'hidden',
    '& > :last-child': {
      borderBottom: 'none',
    },
    '& > :first-child': {
      borderTop: 'none',
    },
  };
});

const Agenda: React.FC<IAgenda> = ({ agenda, toggleAgendaItem }) => {
  const [lottie, setLottie] = useState(null);
  return (
    <Section>
      <Box
        aria-hidden='true'
        sx={{
          position: { xs: 'static', md: 'absolute' },
          width: '100%',
          marginBottom: { xs: '5rem', md: '0' },
          overflow: 'hidden',
          mt: { xs: 0, md: '5rem', xl: 0 },
        }}
      >
        <Player
          lottieRef={(instance) => {
            setLottie(instance);
          }}
          onEvent={(event) => {
            if (event === 'load') {
              create({
                mode: 'scroll',
                player: lottie,
                actions: [
                  { visibility: [0.25, 1], type: 'playOnce', frames: [0, 200] },
                ],
              });
            }
          }}
          src={BlackThreadsAnimationIn}
          autoplay={true}
          loop={false}
          keepLastFrame={true}
          style={{ width: '115%', height: '115%' }}
        />
      </Box>

      <Container>
        <Row>
          <Column xs={24} xl={18} xlOffset={3}>
            <Stack
              direction={{ xs: 'column', md: 'row' }}
              alignItems={{ xs: 'center', md: 'start' }}
            >
              <Typography
                sx={{
                  width: { xs: '90%', md: '50%' },
                  zIndex: 1,
                  textAlign: { xs: 'center', md: 'left' },
                  marginBottom: { xs: '50px', md: '0' },
                }}
                theme='threads-event-23'
                variant='h2'
              >
                What's happening?
              </Typography>
              <Box
                sx={{
                  width: { xs: '100%', md: '50%' },
                  zIndex: 1,
                }}
              >
                <AgendaList>
                  {agenda.map((item, index) => {
                    return (
                      <Element name={item.anchorId} key={`et-${index}`}>
                        <AgendaItem
                          key={`ai-${index}`}
                          toggleAgendaItem={toggleAgendaItem}
                          color={item.color}
                          isLast={index === agenda.length - 1}
                          {...item}
                        />
                      </Element>
                    );
                  })}
                </AgendaList>
              </Box>
            </Stack>
          </Column>
        </Row>
      </Container>
    </Section>
  );
};

export default Agenda;
