import React, { FC } from 'react';
import { MenuListProps } from './MainMenu.types';

// components
import { Stack } from 'src/components-v2/Layout';

const MenuList: FC<MenuListProps> = ({ children, sx }) => {
  return (
    <Stack
      component='ul'
      sx={{ flexDirection: { xs: 'column', md: 'row' }, m: '0', ...sx }}
    >
      {children}
    </Stack>
  );
};

export default MenuList;
