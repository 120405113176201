import React, { useState } from 'react';
import { useScroll, animated, useSpring, easings } from '@react-spring/web';
import { animateScroll as scroll } from 'react-scroll';

// components
import {
  Container,
  Row,
  Column as VColumn,
  Stack,
  Box as VBox,
} from 'src/components-v2/Layout';
import { Image } from 'src/components-v2/Media';
import { ThreadsEventButton, FlipClock } from 'src/sections/ThreadsEvent';
import {
  MenuList,
  MenuItem,
  MenuLink,
} from 'src/sections/ThreadsEvent/23/MainMenu';
import { colors } from 'src/components-v2/theme';

// data
import { MenuItemsData } from 'src/sections/ThreadsEvent/23/MainMenu/menu-data';

const Box = animated(VBox);
const Column = animated(VColumn);

interface IMainMenu {
  onClick: (string) => void;
  blurRef: HTMLDivElement;
}

const MainMenu: React.FC<IMainMenu> = ({ onClick, blurRef }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [hasScrolled, setHasScrolled] = useState(false);
  const randomColors = [colors.purple400, '#DBFF00', colors.black300];
  const colorIndex = Math.floor(Math.random() * randomColors.length);

  React.useEffect(() => {
    if (!isOpen) {
      document.body.style.overflowY = 'visible';
    } else {
      document.body.style.overflowY = 'hidden';
    }
    if (blurRef) {
      blurRef.style.transition = 'all 0.6s ease-in-out';
      if (isOpen) {
        blurRef.style.filter = 'blur(8px)';
      } else {
        blurRef.style.filter = 'blur(0px)';
      }
    }
  });

  useScroll({
    onChange: ({ value }) => {
      if (value.scrollY > 48) {
        setHasScrolled(true);
      } else if (value.scrollY < 48) {
        setHasScrolled(false);
      }
    },
  });

  const handleButtonClick = () => {
    setIsOpen(!isOpen);
  };

  // animation styles
  const navShadowAnimation = useSpring({
    from: {
      boxShadow: '0px 0px 0px rgba(0, 0, 0, 0.25)',
    },
    to: {
      boxShadow: hasScrolled
        ? '0px 4px 4px rgba(0, 0, 0, 0.25)'
        : '0px 0px 0px rgba(0, 0, 0, 0.25)',
    },
    config: {
      duration: 300,
      easing: easings.easeInQuad,
    },
  });

  // animation styles
  const mobileBtnAnimation = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: hasScrolled && !isOpen ? 1 : 0,
      zIndex: 100,
    },
    config: {
      duration: 250,
      easing: easings.easeInQuad,
    },
  });

  // animation styles
  const mobileMenuListAnimation = useSpring({
    left: isOpen ? '0' : '100%',
    backgroundColor: isOpen ? randomColors[colorIndex] : colors.black300,
    config: {
      duration: 300,
      easing: easings.easeInQuad,
    },
  });

  return (
    <>
      <Box
        component='nav'
        style={navShadowAnimation}
        sx={{
          backgroundColor: 'black300',
          position: 'fixed',
          width: '100%',
          top: '0',
          zIndex: 99,
          willChange: 'box-shadow',
        }}
      >
        <Container
          sx={{ px: { xs: '1.7rem', sm: '2.1rem', md: '5rem', lg: '10rem' } }}
        >
          <Row>
            <Column xs={24}>
              <Stack
                direction='row'
                justifyContent='space-between'
                alignItems='center'
                sx={{
                  m: '0',
                  height: { xs: '6.5rem', md: '9.6rem' },
                }}
              >
                <Stack
                  direction='row'
                  sx={{
                    flexGrow: { md: 1 },
                    flexBasis: { md: 0 },
                    width: { xs: 48 },
                    zIndex: 100,
                  }}
                >
                  <a
                    role='button'
                    tabIndex={0}
                    onClick={() => {
                      scroll.scrollToTop();
                    }}
                    aria-label='Go to Plaid homepage'
                  >
                    <Image
                      isLazy={false}
                      sx={{
                        width: { xs: '24px', md: '48px' },
                        height: { xs: '24px', md: '48px' },
                        maxWidth: 'none !important',
                      }}
                      src='https://images.ctfassets.net/ss5kfr270og3/Rd9nIjM3ap8HD25qCEnFb/7482b322a2cc2e4a885ab7b6e317a82d/threads-event-plaid-logo-1_2x.png?fm=webp&q=100'
                      alt='Plaid logo'
                      width='100%'
                      height='100%'
                      sources={[
                        {
                          media: '(max-width: 1023px)',
                          srcSet:
                            'https://images.ctfassets.net/ss5kfr270og3/5gWpI183GQsRxZz4P7N5V1/89d549a56022dfb847ef4ebeb70f6286/threads-event-plaid-logo-2_2x.png?&fm=webp&w=48&h=48&q=100',
                        },
                        {
                          media: '(min-width: 1024px)',
                          srcSet:
                            'https://images.ctfassets.net/ss5kfr270og3/Rd9nIjM3ap8HD25qCEnFb/7482b322a2cc2e4a885ab7b6e317a82d/threads-event-plaid-logo-1_2x.png?fm=webp&w=96&h=96&q=100',
                        },
                      ]}
                    />
                  </a>

                  <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                    <FlipClock />
                  </Box>
                </Stack>
                <Stack sx={{ display: { xs: 'flex', md: 'none' } }}>
                  <Box
                    style={mobileBtnAnimation}
                    sx={{ willChange: 'z-index, opacity' }}
                  >
                    <ThreadsEventButton
                      size='small'
                      sx={{ width: '200px', py: { xs: '0.6rem' } }}
                      href='https://threads.plaid.com/begin'
                    >
                      Register Now
                    </ThreadsEventButton>
                  </Box>
                </Stack>
                <Box
                  component='button'
                  role='button'
                  aria-label='Main menu'
                  aria-expanded={isOpen}
                  onClick={handleButtonClick}
                  sx={{
                    display: { xs: 'block', md: 'none' },
                    width: 48,
                    height: 48,
                    zIndex: 100,
                  }}
                >
                  <CloseIcon isOpen={isOpen} />
                </Box>
                <MenuList sx={{ display: { xs: 'none', md: 'flex' } }}>
                  {MenuItemsData.map((menuItem, i) => {
                    return (
                      <MenuItem key={`menuItem${i}`}>
                        <MenuLink
                          setIsOpen={setIsOpen}
                          onClick={onClick}
                          scrollTo={menuItem.scrollTo}
                        >
                          {menuItem.title}
                        </MenuLink>
                      </MenuItem>
                    );
                  })}
                </MenuList>
                <Stack
                  sx={{
                    flexGrow: 1,
                    flexBasis: 0,
                    alignItems: 'self-end',
                    display: { xs: 'none', md: 'flex' },
                  }}
                >
                  <ThreadsEventButton
                    size='small'
                    href='https://threads.plaid.com/begin'
                  >
                    Register Now
                  </ThreadsEventButton>
                </Stack>
              </Stack>
            </Column>
          </Row>
          <Box
            style={mobileMenuListAnimation}
            sx={{
              boxSizing: 'border-box',
              display: { md: 'none' },
              height: '100vh',
              top: 0,
              position: 'absolute',
              backgroundColor: 'black300',
              zIndex: 99,
              width: '100%',
              px: { xs: '1.7rem', sm: '2.1rem' },
              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
              willChange: 'background-color',
            }}
          >
            <MenuList
              sx={{
                m: '6.4rem 0 0 0',
                listStyle: 'none',
              }}
            >
              {MenuItemsData.map((menuItem, i) => {
                return (
                  <MenuItem key={`menuItem${i}`} sx={{ borderBottom: 1 }}>
                    <MenuLink
                      setIsOpen={setIsOpen}
                      onClick={onClick}
                      scrollTo={menuItem.scrollTo}
                      sx={{ mb: '0.8rem' }}
                    >
                      {menuItem.title}
                    </MenuLink>
                  </MenuItem>
                );
              })}
              <MenuItem sx={{ justifyContent: 'center', pt: '4.5rem' }}>
                <ThreadsEventButton
                  size='small'
                  href='https://threads.plaid.com/begin'
                >
                  Register Now
                </ThreadsEventButton>
              </MenuItem>
            </MenuList>
          </Box>
        </Container>
      </Box>
    </>
  );
};

const CloseIcon = ({ isOpen }) => {
  const topLine = useSpring({
    from: {
      width: 16,
      transform: 'rotate(0deg) translate(4px, 0)',
    },
    to: {
      width: isOpen ? 8 : 16,
      transform: isOpen
        ? 'rotate(45deg) translate(12px, -7px)'
        : 'rotate(0deg) translate(4px, 0px)',
    },
    config: {
      duration: 150,
      easing: easings.easeInQuad,
    },
  });
  const middleLine = useSpring({
    from: {
      transform: 'translate(3.3px)',
    },
    to: {
      transform: 'translate(0px)',
    },
    config: {
      duration: 150,
      easing: easings.easeInQuad,
    },
  });
  const bottomLine = useSpring({
    from: {
      width: 16,
      transform: 'rotate(0deg) translate(4px, 12px)',
    },
    to: {
      width: isOpen ? 8 : 16,
      transform: isOpen
        ? 'rotate(-45deg) translate(3.3px, 15.6px)'
        : 'rotate(0deg) translate(4px, 12px)',
    },
    config: {
      duration: 150,
      easing: easings.easeInQuad,
    },
  });

  return (
    <Box
      sx={{
        position: 'relative',
        transform: 'translate(-26%)',
        top: '-14%',
        left: 15,
        zIndex: 100,
      }}
    >
      <Box
        component='span'
        style={topLine}
        sx={{
          position: 'absolute',
          width: 16,
          height: 2,
          backgroundColor: 'black',
          willChange: 'width',
        }}
      />
      <Box
        component='span'
        style={middleLine}
        sx={{
          position: 'absolute',
          right: 4,
          top: 6,
          width: 16,
          height: 2,
          backgroundColor: 'black',
        }}
      />
      <Box
        component='span'
        style={bottomLine}
        sx={{
          position: 'absolute',
          width: 16,
          height: 2,
          backgroundColor: 'black',
          willChange: 'width',
        }}
      />
    </Box>
  );
};

export default MainMenu;
