import React from 'react';
import styled from '@mui/system/styled';
import { Player } from '@lottiefiles/react-lottie-player';

// components
import { Section, Box } from 'src/components-v2/Layout';
import {
  Typography,
  VisuallyHiddenContainer,
} from 'src/components-v2/DataDisplay';
import { Image } from 'src/components-v2/Media';
import { ThreadsEventButton } from 'src/sections/ThreadsEvent/23/ThreadsEventButton';

const StyledSup = styled('sup')(({ theme }) => {
  return {
    position: 'absolute',
    top: '-6px',
    fontSize: '1.8rem',
    lineHeight: '1.1rem',
    letterSpacing: '-0.067rem',
    fontWeight: '700',
    [theme.breakpoints.up('sm')]: {
      fontSize: '4rem',
      top: '-17px',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '4.8rem',
      lineHeight: '3.2rem',
      top: '-24px',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '5rem',
      lineHeight: '3.1rem',
      letterSpacing: '-0.2rem',
      top: '-15px',
    },
  };
});

const Break = styled('br')(({ theme }) => {
  return {
    display: 'block',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  };
});

const Pipe = styled('span')(({ theme }) => {
  return {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'inline',
      fontWeight: '600',
      padding: '0.5rem',
    },
  };
});

const Hero = ({ animationData }) => {
  return (
    <Section
      component='header'
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
      }}
    >
      <VisuallyHiddenContainer>
        <Typography
          variant='h1'
          theme='threads-event-23'
          sx={{ position: 'relative', pb: { xs: '2.833rem', md: '4.85rem' } }}
        >
          Threads
          <StyledSup>
            <Box
              sx={{
                position: 'relative',
                overflow: 'hidden',
                border: { xs: 0.8, sm: 1, md: 2 },
                borderColor: 'black',
                borderRadius: '50%',
                px: { xs: '0.5rem', sm: '1rem', lg: '1.4rem' },
                py: { xs: '0.1rem', lg: '0.3rem' },
              }}
            >
              23
              <Image
                isLazy={false}
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                }}
                src='https://images.ctfassets.net/ss5kfr270og3/6TwuAgJ5G7Cm6rnPbwIBbp/0c952d2e5ed24db1c9f1fdbf6df4c5fd/threads-event-bg-vector.png?w=170&fm=webp&q=100'
                alt=''
                width='85'
                height='85'
                sources={[
                  {
                    media: '(max-width: 639px)',
                    srcSet:
                      'https://images.ctfassets.net/ss5kfr270og3/6TwuAgJ5G7Cm6rnPbwIBbp/0c952d2e5ed24db1c9f1fdbf6df4c5fd/threads-event-bg-vector.png?w=60&fm=webp&q=100',
                  },
                  {
                    media: '(min-width: 640px)',
                    srcSet:
                      'https://images.ctfassets.net/ss5kfr270og3/6TwuAgJ5G7Cm6rnPbwIBbp/0c952d2e5ed24db1c9f1fdbf6df4c5fd/threads-event-bg-vector.png?w=170&fm=webp&q=100',
                  },
                ]}
              />
            </Box>
          </StyledSup>
        </Typography>
      </VisuallyHiddenContainer>
      <Box
        aria-hidden='true'
        sx={{
          minWidth: {
            xs: '512px',
            sm: '1024px',
            md: '1280px',
            lg: '1440px',
            xl: '100%',
          },
          minHeight: { xs: '78px', sm: '162px', md: '202px', lg: '227px' },
        }}
      >
        <Player
          src={animationData}
          autoplay={true}
          loop={false}
          keepLastFrame={true}
          style={{ width: '100%', height: '100%' }}
        />
      </Box>
      <Typography
        component='h2'
        variant='h3'
        theme='threads-event-23'
        sx={{
          pb: { xs: '2.2rem', md: '5.2rem' },
        }}
      >
        A Virtual Plaid Event <Break />
        <Pipe>|</Pipe> June 22 at 9 AM PT
      </Typography>
      <ThreadsEventButton size='large' href='https://threads.plaid.com/begin'>
        Register Now
      </ThreadsEventButton>
    </Section>
  );
};

export default Hero;
