import React, { FC } from 'react';
import styled from '@mui/system/styled';
import { animated, useSpring, easings } from '@react-spring/web';
import { nanoid } from 'nanoid';
import ChevronDownS1 from 'plaid-threads/Icons/ChevronDownS1';
import useMeasure from 'react-use-measure';
import { Element } from 'react-scroll';

import { Typography } from 'src/components-v2/DataDisplay';
import { Stack as VStack, Box as VBox } from 'src/components-v2/Layout';
import { colors } from 'src/components-v2/theme';

import { IAgendaItem } from './AgendaItem.types';

const Box = animated(VBox);
const Stack = animated(VStack);

const StyledChevron = styled(ChevronDownS1)(() => {
  return { color: 'black !important' };
});

const AgendaToggleButton = styled('button')(() => {
  return {
    display: 'block',
    width: '100%',
    padding: '2.4rem 0',
  };
});

const AgendaSubList = styled('dl')(() => {
  return {
    margin: '0',
    listStyle: 'none',
  };
});

const AgendaListItem = animated(
  styled('div')(() => {
    return { mb: 0 };
  }),
);

const useColorAnimation = ({ isChild, isOpen, color }) => {
  const colorMap = {
    yellow: '#DBFF00',
    white: colors.black300,
    purple: colors.purple400,
  };
  const bgColor = colorMap[color];
  const colorAnimation = useSpring({
    from: {
      backgroundColor: isChild ? bgColor : colors.black300,
    },
    to: {
      backgroundColor: isOpen ? bgColor : isChild ? bgColor : colors.black300,
    },
    config: {
      duration: 150,
      easing: easings.easeInQuad,
    },
  });
  return colorAnimation;
};

const AgendaItem: FC<IAgendaItem> = ({
  body,
  speaker,
  isChild = false,
  isOpen,
  items = [],
  title,
  toggleAgendaItem,
  variant = 'primary',
  color = 'purple',
  isLast = false,
}) => {
  const [contentRef, contentBounds] = useMeasure();
  const [childContentRef, childContentBounds] = useMeasure();

  const handleClick = () => {
    toggleAgendaItem(title);
  };

  const agendaId = `agenda-${nanoid()}`;

  const itemAnimation = useSpring({
    from: { height: 0 },
    to: {
      height: isOpen ? contentBounds.height : 0,
    },
    config: {
      duration: 150,
      easing: easings.easeInQuad,
    },
  });

  const subAnimation = useSpring({
    from: { height: 0 },
    to: {
      height: isOpen ? childContentBounds.height : 0,
    },
    config: {
      duration: 150,
      easing: easings.easeInQuad,
    },
  });

  const caretAnimation = useSpring({
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: isOpen ? 'rotate(-180deg)' : 'rotate(0deg)',
    },
    config: {
      duration: 150,
      easing: easings.easeInQuad,
    },
  });

  const colorAnimation = useColorAnimation({ isChild, isOpen, color });

  return (
    <AgendaListItem
      id={`agenda - ${title}`}
      sx={{
        borderBottom:
          variant === 'secondary'
            ? 'none'
            : isLast
            ? 'none'
            : '1px solid black',
        willChange: 'background-color',
      }}
      style={colorAnimation}
    >
      <AgendaToggleButton
        onClick={handleClick}
        aria-expanded={isOpen}
        aria-controls={agendaId}
        type='button'
        id={`${agendaId}-control`}
      >
        <Stack
          component='dt'
          direction='row'
          alignItems='center'
          justifyContent='start'
          sx={{
            width: '100%',
          }}
        >
          <Typography
            variant={variant === 'secondary' ? 'h6' : 'h5'}
            component={variant === 'secondary' ? 'h4' : 'h3'}
            theme='threads-event-23'
            sx={{
              textAlign: 'left',
              fontWeight: variant === 'secondary' ? '400' : '700',
              paddingLeft: '3.5rem',
              margin: 0,
            }}
          >
            {title}
          </Typography>
          <Stack
            alignItems='center'
            justifyContent='center'
            style={caretAnimation}
            sx={{ ml: '.5rem', pt: '.3rem' }}
          >
            <StyledChevron />
          </Stack>
        </Stack>
      </AgendaToggleButton>
      {body && (
        <Box
          component='dd'
          style={itemAnimation}
          id={agendaId}
          aria-labelledby={`${agendaId}-control`}
          role='region'
          sx={{
            overflowY: 'hidden',
            willChange: 'height',
          }}
        >
          <Stack ref={contentRef}>
            {speaker && (
              <Typography
                ref={contentRef}
                variant='p'
                theme='threads-event-23'
                sx={{
                  mb: 0,
                  textAlign: 'left',
                  paddingLeft: '3.5rem',
                  width: '90%',
                  fontWeight: 800,
                  pb: '1.2rem',
                }}
              >
                Speaker: {speaker}
              </Typography>
            )}
            <Typography
              variant='p'
              theme='threads-event-23'
              sx={{
                mb: 0,
                pb: '2.4rem',
                textAlign: 'left',
                paddingLeft: '3.5rem',
                width: '90%',
              }}
            >
              {body}
            </Typography>
          </Stack>
        </Box>
      )}
      {items.length > 0 && (
        <Box
          component='dd'
          style={subAnimation}
          sx={{ overflowY: 'hidden', willChange: 'height' }}
        >
          <AgendaSubList ref={childContentRef}>
            {items.map((item, index) => {
              return (
                <Element name={item.anchorId} key={'e' + index}>
                  <AgendaItem
                    isChild
                    variant='secondary'
                    toggleAgendaItem={toggleAgendaItem}
                    color={color}
                    key={'item-' + index}
                    {...item}
                  />
                </Element>
              );
            })}
          </AgendaSubList>
        </Box>
      )}
    </AgendaListItem>
  );
};

export default AgendaItem;
