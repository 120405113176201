export const MenuItemsData = [
  {
    title: 'Event',
    scrollTo: {
      id: 'event-anchor',
      duration: 1000,
      delay: 0,
      smooth: 'easeInOutQuart',
      offset: -125, // TODO: compute how far to scroll?
    },
  },
  {
    title: 'Speakers',
    scrollTo: {
      id: 'speakers-anchor',
      duration: 1000,
      delay: 0,
      smooth: 'easeInOutQuart',
      offset: -125,
    },
  },
  {
    title: 'Agenda',
    scrollTo: {
      id: 'agenda-anchor',
      duration: 1000,
      delay: 0,
      smooth: 'easeInOutQuart',
      offset: -125,
    },
  },
  {
    title: 'FAQ',
    scrollTo: {
      id: 'faq-anchor',
      duration: 1000,
      delay: 0,
      smooth: 'easeInOutQuart',
      offset: -160,
    },
  },
];
