import React, { FC } from 'react';
import { MenuLinkProps } from './MainMenu.types';
import styled from '@mui/system/styled';
import { colors } from 'src/components-v2/theme';

// components
import { Typography } from 'src/components-v2/DataDisplay';

const StyledA = styled('a')(() => {
  return {
    color: colors.black,
    '&:hover, :focus, :active': {
      color: colors.blue800,
    },
    transition: 'color 0.1s ease-in-out',
    padding: '3.6rem 0',
    width: '100%',
  };
});

const MenuLink: FC<MenuLinkProps> = ({
  children,
  onClick,
  sx,
  scrollTo,
  setIsOpen,
}) => {
  return (
    <StyledA
      onClick={() => {
        setIsOpen(false);
        onClick(scrollTo);
      }}
      role='button'
      tabIndex={0}
    >
      <Typography
        theme='threads-event-23'
        component='span'
        variant='p'
        sx={{
          fontSize: '1.4rem',
          fontWeight: '600',
          lineHeight: '2rem',
          letterSpacing: '0.028rem',
          px: { md: '2rem', lg: '3rem' },
          m: '0',
          color: 'inherit',
          ...sx,
        }}
      >
        {children}
      </Typography>
    </StyledA>
  );
};

export default MenuLink;
