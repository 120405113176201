export const speakersRow1 = [
  {
    title: 'Zach Perret',
    image: {
      src:
        'https://images.ctfassets.net/ss5kfr270og3/5EMojufFHw1TIK1ZdwgLuE/08db7bf8523a476ef580d4bdb43bd2e9/Marquee-Thumbnails_0000_Zach.jpg',
      alt: 'Headshot of Zach Perret',
    },
    bgImageStyles: {
      left: '-5%',
      bottom: '-88%',
      transform: 'rotate(190deg)',
    },
    scrollTo: {
      id: 'speaker-Zach Perret',
      duration: 1000,
      delay: 0,
      smooth: 'easeInOutQuart',
      offset: -250,
    },
  },
  {
    title: 'Michelle Boros',
    image: {
      src:
        'https://images.ctfassets.net/ss5kfr270og3/5m3Na9ycXvb17KT098JNIJ/fbef6a870188f94182feda4e33a00be6/Marquee-Thumbnails_0004_Michelle.jpg',
      alt: 'Headshot of Michelle Boros',
    },
    bgImageStyles: {
      left: '-14%',
      bottom: '-70%',
      transform: 'rotate(190deg)',
    },
    scrollTo: {
      id: 'speaker-Michelle Boros',
      duration: 1000,
      delay: 0,
      smooth: 'easeInOutQuart',
      offset: -250,
    },
  },
  {
    title: 'Jean-Denis Greze',
    image: {
      src:
        'https://images.ctfassets.net/ss5kfr270og3/7zdAqsiZLqqgMAUUV7Wi7H/0b8da3601a97b5675c65abdd33553151/Marquee-Thumbnails_0006_JDG.jpg',
      alt: 'Headshot of Jean-Denis Greze',
    },
    bgImageStyles: {
      right: '-37%',
      top: '-75%',
      transform: 'rotate(5deg)',
    },
    scrollTo: {
      id: 'speaker-Jean-Denis Greze',
      duration: 1000,
      delay: 0,
      smooth: 'easeInOutQuart',
      offset: -250,
    },
  },
  {
    title: 'Ashley Cornall',
    image: {
      src:
        'https://images.ctfassets.net/ss5kfr270og3/2qCbGDpnftxu645bBUShnl/46bcc14fd3169cdb1d11df51928ebf75/Marquee-Thumbnails_0007_Ashley.jpg',
      alt: 'Headshot of Ashley Cornall',
    },
    bgImageStyles: {
      left: '-14%',
      bottom: '-70%',
      transform: 'rotate(190deg)',
    },
    scrollTo: {
      id: 'speaker-Ashley Cornall',
      duration: 1000,
      delay: 0,
      smooth: 'easeInOutQuart',
      offset: -250,
    },
  },
];

export const speakersRow2 = [
  {
    title: 'Alain Meier',
    image: {
      src:
        'https://images.ctfassets.net/ss5kfr270og3/5bxeK0cUETU3Ok3nCWB5er/8ef841c6520e12ca7b3584386c0028e7/Marquee-Thumbnails_0008_Alain_Yellow.png',
      alt: 'Headshot of Alain Meier',
    },
    bgImageStyles: {
      left: '-13%',
      top: '-70%',
      transform: 'rotate(-6deg)',
    },
    scrollTo: {
      id: 'speaker-Alain Meier',
      duration: 1000,
      delay: 0,
      smooth: 'easeInOutQuart',
      offset: -250,
    },
  },
  {
    title: 'Sheila Jambekar',
    image: {
      src:
        'https://images.ctfassets.net/ss5kfr270og3/4qvng2LGJ2za6RMqAAB972/94b07ab83f734fb548bead45ce5564ce/Marquee-Thumbnails_0002_Sheila_Yellow.png',
      alt: 'Headshot of Sheila Jambekar',
    },
    bgImageStyles: {
      right: '-20%',
      top: '-86%',
      transform: 'rotate(10deg)',
    },
    scrollTo: {
      id: 'speaker-Sheila Jambekar',
      duration: 1000,
      delay: 0,
      smooth: 'easeInOutQuart',
      offset: -250,
    },
  },
  {
    title: 'John Anderson',
    image: {
      src:
        'https://images.ctfassets.net/ss5kfr270og3/3Tf90iHsWB6wNj8NKpAdtJ/d44f3675f341326717663cf33c94ac7c/Marquee-Thumbnails_0005_John_Yellow.png',
      alt: 'Headshot of John Anderson',
    },
    bgImageStyles: {
      right: '-48%',
      bottom: '-64%',
      transform: 'rotate(170deg)',
    },
    scrollTo: {
      id: 'speaker-John Anderson',
      duration: 1000,
      delay: 0,
      smooth: 'easeInOutQuart',
      offset: -250,
    },
  },
  {
    title: 'Eric Sager',
    image: {
      src:
        'https://images.ctfassets.net/ss5kfr270og3/3WPUenaFsfKkfmqQqd9EFj/48455284230269c3fd3c3d7b396835c4/Marquee-Thumbnails_009_Eric-Sager.png',
      alt: 'Headshot of Eric Sager',
    },
    bgImageStyles: {
      right: '-37%',
      top: '-75%',
      transform: 'rotate(5deg)',
    },
    scrollTo: {
      id: 'speaker-Eric Sager',
      duration: 1000,
      delay: 0,
      smooth: 'easeInOutQuart',
      offset: -250,
    },
  },
];

export const agendas = [
  {
    title: 'Keynote',
    scrollTo: {
      id: 'agenda-Opening Keynote',
      duration: 1000,
      delay: 0,
      smooth: 'easeInOutQuart',
      offset: -250,
    },
    bgImageStyles: {
      left: '-34%',
      bottom: '-83%',
      transform: 'rotate(190deg)',
    },
  },
  {
    title: 'New Products',
    scrollTo: {
      id: 'agenda-Product Updates',
      duration: 1000,
      delay: 0,
      smooth: 'easeInOutQuart',
      offset: -150,
    },
    bgImageStyles: {
      right: '-38%',
      bottom: '-91%',
      transform: 'rotate(169deg)',
    },
  },
  {
    title: 'Demos',
    scrollTo: {
      id: 'agenda-Product Updates',
      duration: 1000,
      delay: 0,
      smooth: 'easeInOutQuart',
      offset: -250,
    },
    bgImageStyles: {
      left: '-10%',
      bottom: '-75%',
      transform: 'rotate(183deg)',
    },
  },
  {
    title: 'Updated Features',
    scrollTo: {
      id: 'agenda-Product Updates',
      duration: 1000,
      delay: 0,
      smooth: 'easeInOutQuart',
      offset: -250,
    },
    bgImageStyles: {
      right: '-51%',
      bottom: '-76%',
      transform: 'rotate(170deg)',
    },
  },
];

export const customerStories = {
  title: 'Customer stories featuring',
  scrollTo: {
    id: 'agenda-Customer Stories',
    duration: 1000,
    delay: 0,
    smooth: 'easeInOutQuart',
    offset: -250,
  },
  customerStories: [
    {
      title: 'Rocket Money',
      image: {
        src:
          'https://images.ctfassets.net/ss5kfr270og3/6RgyoE0EyfDj8iqeyEhOHq/c81688c4b5d737e02cec3e9c20ec355e/Marquee-Thumbnails_RM-Logo.png',
        alt: 'rocket money logo',
      },
      scrollTo: {
        id: 'agenda-Customer Stories',
        duration: 1000,
        delay: 0,
        smooth: 'easeInOutQuart',
        offset: -250,
      },
    },
    {
      title: 'Esusu',
      image: {
        src:
          'https://images.ctfassets.net/ss5kfr270og3/7qgohZadx8CDWJU6wAQKcC/9f02c0d77656ebb2b1b79e7b5addedf6/Esusu.png',
        alt: 'Esusu logo',
      },
      scrollTo: {
        id: 'agenda-Customer Stories',
        duration: 1000,
        delay: 0,
        smooth: 'easeInOutQuart',
        offset: -250,
      },
    },
  ],
  bgImageStyles: [
    {
      left: '-10%',
      bottom: '-75%',
      transform: 'rotate(183deg)',
    },
    {
      right: '-20%',
      top: '-86%',
      transform: 'rotate(10deg)',
    },
  ],
};
