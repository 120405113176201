import React, { FC } from 'react';
import { CustomerStoryItemProps } from './Marquee.types';
import { Box, Stack } from 'src/components-v2/Layout';
import { Typography } from 'src/components-v2/DataDisplay';
import { Image } from 'src/components-v2/Media';
import { MarqueeBackgroundImage } from 'src/sections/ThreadsEvent/23/Marquee';

const CustomerStoryItem: FC<CustomerStoryItemProps> = ({
  title,
  scrollTo,
  customerStories,
  bgImageStyles,
  handleClick,
}) => {
  return (
    <Box
      component='li'
      sx={{
        listStyleType: 'none',
        borderRight: { xs: 0.7, md: 1 },
        py: { xs: '1.2rem', md: '2rem' },
        px: { xs: '2.5rem', md: '4rem' },
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      {bgImageStyles.map((style, i) => {
        return (
          <MarqueeBackgroundImage styles={style} key={`bgImageStyle-${i}`} />
        );
      })}
      <Stack flexDirection='row' alignItems='center' gap='2rem'>
        <Box
          component='button'
          role='button'
          onClick={() => {
            return handleClick(scrollTo);
          }}
        >
          <Typography
            variant='h2'
            component='span'
            theme='threads-event-23'
            sx={{
              fontSize: '3.6rem',
              lineHeight: '5.4rem',
              letterSpacing: '-0.254rem',
              px: { xs: '1rem', md: '1.6rem' },
              mb: '0',
            }}
          >
            {title}
          </Typography>
        </Box>
        {customerStories.map((customerStory, i) => {
          const { title, image } = customerStory;
          return (
            <Box
              key={`customerStory-${i}`}
              component='button'
              role='button'
              onClick={() => {
                return handleClick(customerStory.scrollTo);
              }}
            >
              <Image
                key={`${title}-customer-story-img-${i}`}
                sx={{
                  border: 1,
                  borderRadius: '71px',
                  width: { xs: '192px', md: '220px', lg: '320px' },
                  height: { xs: '60px', md: '70px', lg: '99px' },
                }}
                isLazy={false}
                src={image.src + '?fm=webp&w=642&q=70'}
                alt={image.alt}
                width='320px'
                height='99px'
                sources={[
                  {
                    media: '(max-width: 639px)',
                    srcSet: `${image.src + '?fm=webp&w=384&h=120&q=100'}`,
                  },
                  {
                    media: '(min-width: 640px)',
                    srcSet: `${image.src + '?fm=webp&w=642&q=90'}`,
                  },
                ]}
              />
            </Box>
          );
        })}
        <Box
          component='button'
          role='button'
          onClick={() => {
            return handleClick(scrollTo);
          }}
        >
          <Typography
            variant='h2'
            component='span'
            theme='threads-event-23'
            sx={{
              fontSize: '3.6rem',
              lineHeight: '5.4rem',
              letterSpacing: '-0.254rem',
              px: { xs: '1rem', md: '1.6rem' },
              mb: '0',
            }}
          >
            and more...
          </Typography>
        </Box>
      </Stack>
    </Box>
  );
};
export default CustomerStoryItem;
