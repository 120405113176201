import React from 'react';
import { default as ReactMarquee } from 'react-fast-marquee';
import { Section } from 'src/components-v2/Layout';
import {
  MarqueeList,
  MarqueeItem,
  CustomerStoryItem,
} from 'src/sections/ThreadsEvent/23/Marquee';
import {
  speakersRow1,
  agendas,
  speakersRow2,
  customerStories,
} from 'src/sections/ThreadsEvent/23/Marquee/marquee-data';

interface IMarquee {
  handleClick: (string) => void;
}

const Marquee: React.FC<IMarquee> = ({ handleClick }) => {
  return (
    <Section
      sx={{
        minHeight: { xs: '320px', sm: '248px', md: '330px', lg: '413px' },
      }}
    >
      <ReactMarquee
        pauseOnHover={true}
        pauseOnClick={true}
        gradientWidth={20}
        gradientColor={[237, 237, 237]}
        key='1'
      >
        <MarqueeList isTopRow>
          {speakersRow1.map((speaker, i) => {
            return (
              <MarqueeItem
                key={`speaker1-${i}`}
                title={speaker.title}
                image={speaker.image}
                bgImageStyles={speaker.bgImageStyles}
                scrollTo={speaker.scrollTo}
                handleClick={handleClick}
              />
            );
          })}
          {speakersRow1.map((speaker, i) => {
            return (
              <MarqueeItem
                key={`speaker2-${i}`}
                title={speaker.title}
                image={speaker.image}
                bgImageStyles={speaker.bgImageStyles}
                scrollTo={speaker.scrollTo}
                handleClick={handleClick}
              />
            );
          })}
        </MarqueeList>
      </ReactMarquee>
      <ReactMarquee
        direction='right'
        pauseOnHover={true}
        pauseOnClick={true}
        gradientWidth={20}
        gradientColor={[237, 237, 237]}
        speed={30}
        key='3'
      >
        <MarqueeList>
          {speakersRow2.map((speaker, i) => {
            return (
              <MarqueeItem
                key={`speaker-row-21-${i}`}
                title={speaker.title}
                image={speaker.image}
                bgImageStyles={speaker.bgImageStyles}
                scrollTo={speaker.scrollTo}
                handleClick={handleClick}
              />
            );
          })}
          {speakersRow2.map((speaker, i) => {
            return (
              <MarqueeItem
                key={`speaker-row-22-${i}`}
                title={speaker.title}
                image={speaker.image}
                bgImageStyles={speaker.bgImageStyles}
                scrollTo={speaker.scrollTo}
                handleClick={handleClick}
              />
            );
          })}
        </MarqueeList>
      </ReactMarquee>
      <ReactMarquee
        pauseOnHover={true}
        pauseOnClick={true}
        gradientWidth={20}
        gradientColor={[237, 237, 237]}
        speed={35}
        key='2'
      >
        <MarqueeList>
          <CustomerStoryItem {...customerStories} handleClick={handleClick} />
          {agendas.map((agenda, i) => {
            return (
              <MarqueeItem
                key={`agenda1-${i}`}
                title={agenda.title}
                scrollTo={agenda.scrollTo}
                bgImageStyles={agenda.bgImageStyles}
                handleClick={handleClick}
              />
            );
          })}
        </MarqueeList>
      </ReactMarquee>
    </Section>
  );
};

export default Marquee;
