import React, { FC } from 'react';
import { MarqueeListProps } from './Marquee.types';

// components
import { Stack } from 'src/components-v2/Layout';

const MarqueeList: FC<MarqueeListProps> = ({ children, isTopRow }) => {
  return (
    <Stack
      component='ul'
      flexDirection='row'
      sx={{
        borderTop: { xs: 0.7, md: 1 },
        borderBottom: { xs: 0.7, md: 1 },
        borderLeft: { xs: 0.7, md: 1 },
        m: '0',
        mt: isTopRow ? '0.1rem' : '-0.1rem',
        mr: '-0.1rem',
      }}
    >
      {children}
    </Stack>
  );
};

export default MarqueeList;
