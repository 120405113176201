import React from 'react';

// components
import {
  Section,
  Container,
  Row,
  Column,
  Stack,
} from 'src/components-v2/Layout';
import { ThreadsEventButton } from 'src/sections/ThreadsEvent';
import { Typography } from 'src/components-v2/DataDisplay';

const secureYourSpotText =
  "Don't miss out on the new products, feature updates, and insights to help you grow your business.";

const SecureYourSpot = () => {
  return (
    <Section>
      <Container>
        <Row>
          <Column
            xs={24}
            xl={18}
            xlOffset={3}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              bgcolor: 'black1000',
              borderRadius: '20px',
              height: { xs: '400px', md: '500px' },
            }}
          >
            <Stack
              alignItems='center'
              justifyContent='space-between'
              sx={{ height: { xs: '280px', md: '375px' } }}
            >
              <Typography
                variant='h2'
                component='h3'
                sx={{ color: 'white', textAlign: 'center' }}
                theme='threads-event-23'
              >
                Secure your spot
              </Typography>
              <Typography
                variant='p'
                sx={{
                  display: { xs: 'block', md: 'none' },
                  color: 'white',
                  width: '90%',
                  maxWidth: '450px',
                  textAlign: 'center',
                }}
                theme='threads-event-23'
              >
                {secureYourSpotText}
              </Typography>
              <Typography
                variant='h4'
                component='p'
                sx={{
                  display: { xs: 'none', md: 'block' },
                  color: 'white',
                  width: { md: '70%', xl: '100%' },
                  textAlign: 'center',
                  maxWidth: '760px',
                }}
                theme='threads-event-23'
              >
                {secureYourSpotText}
              </Typography>
              <ThreadsEventButton
                color='white'
                size='large'
                sx={{ color: 'inherit' }}
                href='https://threads.plaid.com/begin'
              >
                Register Now
              </ThreadsEventButton>
            </Stack>
          </Column>
        </Row>
      </Container>
    </Section>
  );
};

export default SecureYourSpot;
