import * as React from 'react';
import styled from '@mui/system/styled';

import { Container, Section, Row, Column } from 'src/components-v2/Layout';
import { Typography } from 'src/components-v2/DataDisplay';

const HighlightedText = styled('em')(({ theme }) => {
  return {
    borderRadius: '20px',
    fontStyle: 'normal',
    boxDecorationBreak: 'clone',
    border: '1px solid black',
    padding: '0 1.4rem',
  };
});

const About = () => {
  return (
    <Section>
      <Container>
        <Row>
          <Column xs={24} xl={18} xlOffset={3}>
            <Typography
              sx={{ textAlign: { xs: 'center' } }}
              variant='h2'
              component='p'
              theme='threads-event-23'
            >
              Join us virtually at 9 AM PT on{' '}
              <HighlightedText sx={{ bgcolor: 'purple400', mr: '0.8rem' }}>
                June&nbsp;22
              </HighlightedText>{' '}
              and get access to the latest network-based products and updates
              designed to help you{' '}
              <HighlightedText
                sx={{
                  bgcolor: '#DBFF00',
                  display: { sm: 'none' },
                }}
              >
                build the&nbsp;future of&nbsp;finance.
              </HighlightedText>
              <HighlightedText
                sx={{
                  bgcolor: '#DBFF00',
                  display: { xs: 'none', sm: 'inline' },
                }}
              >
                build&nbsp;the&nbsp;future of&nbsp;finance.
              </HighlightedText>
            </Typography>
          </Column>
        </Row>
      </Container>
    </Section>
  );
};

export default About;
